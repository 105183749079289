export const propertyinfo = {
  repairState: [
    {
      value: '1',
      label: '全部状态'
    },
    {
      value: '2',
      label: '未派发'
    },
    {
      value: '3',
      label: '已派发'
    },
    {
      value: '4',
      label: '进行中'
    },
    {
      value: '5',
      label: '已完成'
    }
  ],
  repairSearchType: [
    {
      value: 'name',
      label: '姓名'
    },
    {
      value: 'mobile',
      label: '联系电话'
    },
    {
      value: 'phase',
      label: '期数'
    },
    {
      value: 'buildingNo',
      label: '房屋号'
    },
    {
      value: 'remark',
      label: '备注'
    }
  ],
  repairSearchType2: [
    {
      value: 'repairTime',
      label: '发起时间'
    }
    // {
    //   value: 'completedTime',
    //   label: '完成时间'
    // }
  ],
  opinionSearchType: [
    {
      value: 'time',
      label: '反馈时间'
    }
  ],
  classify: [
    {
      value: '0',
      label: '不文明现象'
    },
    {
      value: '1',
      label: '违规现象'
    }
  ],
  newsType: [
    {
      value: '1',
      label: '全部类型'
    },
    {
      value: '2',
      label: '重要通知'
    },
    {
      value: '3',
      label: '紧急通知'
    },
    {
      value: '4',
      label: '普通通知'
    }
  ]
}
