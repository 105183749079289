import Vue from 'vue'
import Vuex from 'vuex'
import { CommonState } from './modules/common'
import { PersonState } from './modules/person'
import { SettingsState } from './modules/settings'

Vue.use(Vuex)

export interface RootState {
  common: CommonState
  person: PersonState
  settings: SettingsState
}

export default new Vuex.Store<RootState>({})
