/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * 公共基础接口列表
 */
import axios from './http'

export const common = {
  // 字典查询
  getDictList(params: any) {
    return axios.get('/api/c-pc/dictionary/list', { params })
  },
  // 查询小区期数（一期、二期...）
  getAreaList() {
    return axios.get('/api/c-pc/house/phase/list')
  },
  // 新增小区期数
  addArea(params: any) {
    return axios.post('/api/c-pc/house/phase/add', params)
  },
  // 获取门禁组/车闸组数据
  getAuthGroups(params: any) {
    return axios.get('/api/c-pc/device/list/page', {
      params
    })
  }
}
