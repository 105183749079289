

























import { Component, Prop, Watch, Vue } from 'vue-property-decorator'
import { Dialog } from 'element-ui'
Vue.use(Dialog)
@Component({
  name: 'Dialog'
})
export default class extends Vue {
  @Prop({ default: false }) private loading!: boolean
  @Prop({ default: false }) private visible!: boolean
  // @Prop({ default: '390px' }) private width!: string
  @Prop() private confirm!: Function
  @Prop() private okText!: string
  @Prop() private cancelText!: string
  @Prop({ default: false }) private customFooter!: boolean
  @Prop({ default: true }) private isNeedCancel!: boolean
  private dialogVisible = this.visible
  // 监听器
  @Watch('visible')
  getvisible(val: boolean) {
    this.dialogVisible = val
  }
  doOk() {
    this.confirm()
  }
  doClose() {
    this.$emit('dialogClose', false)
  }
}
