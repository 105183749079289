import { DirectiveOptions } from 'vue'
import { DirectiveBinding } from 'vue/types/options'

const install = (el: HTMLElement, binding: DirectiveBinding, vnode: any) => {
  // v-auth传值 如: v-auth="['add']"
  const current = binding.value
  const u = sessionStorage.getItem('user')
  const prv = u && JSON.parse(u).infs
  if (
    prv.indexOf('/visitors/visitor-list') !== -1 &&
    vnode.context.$route.meta.title === '首页'
  ) {
    vnode.context.$route.meta.authority = ['more']
  } else {
    vnode.context.$route.meta.authority = []
  }
  if (
    prv.indexOf('/property/news') !== -1 &&
    vnode.context.$route.meta.title === '房屋列表'
  ) {
    vnode.context.$route.meta.authority = ['batch-news']
  }
  const authorities = vnode.context.$route.meta.authority || []
  if (authorities.length > 0) {
    const hasPermit = authorities.some((item: any) => current.includes(item))
    if (!hasPermit) {
      el.style.display = 'none'
    }
  } else {
    el.style.display = 'none'
  }
}

export const Auth: DirectiveOptions = {
  bind(el, binding, vnode) {
    install(el, binding, vnode)
  }
}
