export const visitorinfo = {
  searchType: [
    {
      value: 'enterTime',
      label: '进入时间'
    }
    // {
    //   value: 'outTime',
    //   label: '离开时间'
    // }
  ],
  searchType2: [
    {
      value: 'name',
      label: '姓名'
    },
    {
      value: 'mobile',
      label: '手机号'
    }
  ],
  searchType3: [
    {
      value: 'carNo',
      label: '车牌号'
    },
    // {
    //   value: 'reserveInfo',
    //   label: '预约信息'
    // },
    {
      value: 'remark',
      label: '备注'
    }
  ],
  searchType4: [
    {
      value: 'inStartTime',
      label: '入场时间'
    },
    {
      value: 'outStartTime',
      label: '出场时间'
    }
  ],
  searchType5: [
    {
      value: 'appointTime',
      label: '来访日期'
    }
  ],
  searchType6: [
    {
      value: 'carNo',
      label: '车牌号'
    },
    {
      value: 'appointName',
      label: '预约人员'
    },
    {
      value: 'visitorName',
      label: '访客姓名'
    }
  ],
  doorList: [
    {
      value: '1',
      label: '全部'
    },
    {
      value: '2',
      label: '东南门'
    },
    {
      value: '3',
      label: '西门'
    },
    {
      value: '4',
      label: '北门'
    }
  ]
}
