





































/* eslint-disable @typescript-eslint/no-this-alias */
import Vue from 'vue'
import Component from 'vue-class-component'
@Component
export default class Login extends Vue {
  username = ''
  password = ''
  loading = false

  enter() {
    if (this.username === '') {
      return this.$message.warning('请输入用户名')
    }
    if (this.password === '') {
      return this.$message.warning('请输入密码')
    }
    if (!/^[0-9a-zA-Z]+$/.test(this.username)) {
      return this.$message.warning('用户名请输入英文数字')
    }
    if (this.password.length < 6 || !/^[0-9a-zA-Z]+$/.test(this.password)) {
      return this.$message.warning('密码请输入6-20位英文数字')
    }
    this.loading = true
    this.$api.login
      .login({ username: this.username, password: this.password })
      .then((res: any) => {
        this.loading = false
        if (res.data.success) {
          sessionStorage.setItem('user', JSON.stringify(res.data.data))
          if (res.data.data.infs.includes('/gate')) {
            this.$router.push('/gate')
          } else {
            this.$router.push('/home')
          }
        }
      })
      .catch(() => {
        this.loading = false
      })
  }
}
