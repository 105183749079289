

































import Vue from 'vue'
import Component from 'vue-class-component'
import { Container, Header, Main, Aside } from 'element-ui'
import SmartHeader from './Header/index.vue'
import SmartAside from './Aside/index.vue'
import PasswordModal from '@/views/common/password-modal.vue'
Vue.use(Container)
Vue.use(Header)
Vue.use(Main)
Vue.use(Aside)

@Component({
  components: {
    SmartHeader,
    SmartAside,
    PasswordModal
  }
})
export default class Layout extends Vue {
  // 是否弹出修改密码弹窗
  isOpen = false

  temp = {} as any
  newsList = [] as any
  barList = [
    {
      id: 1,
      name: '业主审核',
      value: 'OWNER_AUDIT',
      isRead: true,
      count: 0
    },
    {
      id: 2,
      name: '租客审核',
      value: 'TENANT_AUDIT',
      isRead: true,
      count: 0
    },
    // {
    //   id: 3,
    //   name: '报修管理',
    //   value: 'REPAIR_APPLY',
    //   isRead: true,
    //   count: 0
    // },
    {
      id: 4,
      name: '业主意见',
      value: 'OWNER_OPINION',
      isRead: true,
      count: 0
    }
  ]
  noticeCountList = [] as any
  newsNum = 0
  userId: any

  websocket: any = null
  reconnectData1: any = null
  serverTimeoutObj1: any = null
  lockReconnect1 = false //避免重复连接，因为onerror之后会立即触发 onclose
  timeout = 30000 //30s一次心跳检测
  timeoutObj1: any = null
  // websocketTestUrl =
  //   'ws://community-dev.jiketravel.com/service/c-pc/ws/webNotice'
  // 开发环境
  websocketTestUrl = 'wss://community-test.jiketravel.com/api/c-pc/ws/webNotice'
  // 李本地
  // websocketTestUrl = 'ws://10.0.10.109:8021/service/c-pc/ws/webNotice'
  // 生产环境
  websocketProductUrl = 'wss://community.luopan88.com/api/c-pc/ws/webNotice'

  mounted() {
    const user = sessionStorage.getItem('user')
    const u = user && JSON.parse(user)
    this.userId = user && JSON.parse(user).id
    this.getMessageCount(this.userId)
    if (u && !u.infs.includes('/gate')) {
      this.initWebSocket()
    }
  }

  destroyed() {
    this.websocket.close() //离开路由之后断开websocket连接
  }
  // 弹出修改密码弹窗
  openDialog() {
    this.isOpen = true
  }

  refresh() {
    this.getMessageCount(this.userId)
  }

  // 关闭对话框
  closeDialog() {
    this.isOpen = false
  }
  // 操作对话框后统一回调
  afterOpt(item: any) {
    const u = sessionStorage.getItem('user')
    const userId = u && JSON.parse(u).id
    this.$api.people
      .editPwd({
        id: userId,
        password: item.password,
        rawPassword: item.rawPassword
      })
      .then((res: any) => {
        const data = res.data
        if (data.success) {
          this.closeDialog()
          this.$message.success(data.msg)
          this.$router.push('/')
          sessionStorage.clear()
          localStorage.clear()
        }
      })
    // this.closeDialog()
  }

  getMessageCount(id: any) {
    this.newsNum = 0
    this.$api.home.getNoticeCount(id).then((res: any) => {
      if (res.data.success) {
        this.noticeCountList = res.data.data
        res.data.data.forEach((news: any) => {
          // this.newsNum += news.unreadCount
          this.barList.map((bar: any) => {
            if (
              news.msgType &&
              news.msgType !== 'REPAIR_APPLY' &&
              news.msgType === bar.value
            ) {
              bar.count = news.unreadCount
            } else {
              bar.count = 0
            }
          })
        })
        const temp = {
          JUMIN: 0,
          WUYE: 0,
          OWNER_AUDIT: 0,
          TENANT_AUDIT: 0,
          // REPAIR_APPLY: 0,
          OWNER_OPINION: 0
        } as any
        res.data.data.forEach((news: any) => {
          if (news.msgType !== 'REPAIR_APPLY') {
            this.newsNum = this.newsNum + news.unreadCount
            Object.keys(temp).map((t: any) => {
              if (news.msgType && news.msgType === t) {
                temp[t] = news.uncheckedCount
              }
              this.barList.map((bar: any) => {
                if (news.msgType && news.msgType === bar.value) {
                  bar.count = news.unreadCount
                }
              })
            })
          }
        })
        temp.JUMIN = temp.OWNER_AUDIT + temp.TENANT_AUDIT
        // temp.WUYE = temp.REPAIR_APPLY + temp.OWNER_OPINION
        temp.WUYE = temp.OWNER_OPINION
        this.temp = temp
      }

      // 测试数据
      // const coun = [
      //   { msgType: 'REPAIR_APPLY', uncheckedCount: 18, unreadCount: 6 },
      //   { msgType: 'OWNER_AUDIT', uncheckedCount: 20, unreadCount: 3 },
      //   { msgType: 'TENANT_AUDIT', uncheckedCount: 13, unreadCount: 7 },
      //   { msgType: 'OWNER_OPINION', uncheckedCount: 9, unreadCount: 1 }
      // ]
      // this.noticeCountList = coun
      // const temp = {
      //   JUMIN: 0,
      //   WUYE: 0,
      //   REPAIR_APPLY: 0,
      //   OWNER_AUDIT: 0,
      //   TENANT_AUDIT: 0,
      //   OWNER_OPINION: 0
      // } as any
      // coun.forEach((news: any) => {
      //   if (news.msgType) {
      //     this.newsNum = this.newsNum + news.unreadCount
      //     Object.keys(temp).map((t: any) => {
      //       if (news.msgType && news.msgType === t) {
      //         temp[t] = news.uncheckedCount
      //       }
      //       this.barList.map((bar: any) => {
      //         if (news.msgType && news.msgType === bar.value) {
      //           bar.count = news.unreadCount
      //         }
      //       })
      //     })
      //   }
      // })
      // temp.JUMIN = temp.OWNER_AUDIT + temp.TENANT_AUDIT
      // temp.WUYE = temp.REPAIR_APPLY + temp.OWNER_OPINION
      // console.log('temp====', temp)
      // this.temp = temp
      // }
    })
  }

  //初始化weosocket
  initWebSocket() {
    // 获取当前页面地址
    const wPath = window.document.location.href
    // console.log(
    //   'wPath',
    //   wPath,
    //   wPath.includes('localhost'),
    //   window.document.location.host
    // )
    // console.log('启动中')
    if (wPath.includes('localhost')) {
      this.websocket = new WebSocket(this.websocketTestUrl)
      // console.log('socket连接地址', this.websocketTestUrl)
    } else {
      this.websocketProductUrl =
        'wss://' + window.document.location.host + '/api/c-pc/ws/webNotice'
      this.websocket = new WebSocket(this.websocketProductUrl)
      // console.log('socket连接地址', this.websocketProductUrl)
    }
    this.websocket.onopen = this.websocketonopen //连接成功
    this.websocket.onmessage = this.websocketonmessage //广播成功
    this.websocket.onerror = this.websocketonerror //连接断开，失败
    this.websocket.onclose = this.websocketclose //连接关闭
  }

  websocketonopen() {
    // console.log('wsNotice连接成功')
    this.websocketsend()
    this.heatBeat()
  }

  websocketsend() {
    const user = sessionStorage.getItem('user')
    const userId = user && JSON.parse(user).id
    const token = sessionStorage.getItem('token')
    const message = {
      msgType: 'Web_Notice_Auth',
      msg: userId,
      token: token
    }
    // 向后端发送数据
    // console.log('wsNotice向后端发送数据', message)
    this.websocket.send(JSON.stringify(message))
  }

  websocketonerror(e: any) {
    // console.log('wsNotice连接发生错误', e)
    // 重新连接
    this.reconnect()
  }
  websocketonmessage(e: any) {
    this.heatBeat()
    const data = JSON.parse(e.data)
    // console.log('wsNotice推送的数据====', data)
    if (data.code === 'Web_Notice_Push') {
      // console.log('接收到wsNotice消息通知')
      this.getMessageCount(this.userId)
    }
  }
  websocketclose(e: any) {
    // console.log('wsNotice断开连接', e)
    // 重新连接
    this.reconnect()
  }
  reconnect() {
    // console.log('wsNotice重新连接')
    if (this.lockReconnect1) {
      return
    }
    this.lockReconnect1 = true
    this.reconnectData1 && clearTimeout(this.reconnectData1)
    this.reconnectData1 = setTimeout(() => {
      this.initWebSocket()
      this.lockReconnect1 = false
    }, 4000)
  }
  heatBeat() {
    this.timeoutObj1 && clearTimeout(this.timeoutObj1)
    this.serverTimeoutObj1 && clearTimeout(this.serverTimeoutObj1)
    this.timeoutObj1 = setTimeout(() => {
      //这里发送一个心跳，后端收到后，返回一个心跳消息
      const user = sessionStorage.getItem('user')
      const userId = user && JSON.parse(user).id
      const token = sessionStorage.getItem('token')
      const message = {
        msgType: 'Web_Notice_Auth',
        msg: userId,
        token: token
      }
      // console.log('wsNotice心跳检测，向后端发送数据', message)
      this.websocket.send(JSON.stringify(message))
      this.serverTimeoutObj1 = setTimeout(() => {
        this.websocket.close() //如果  5秒之后我们没有收到 后台返回的心跳检测数据 断开socket，断开后会启动重连机制
      }, 5000)
    }, this.timeout)
  }
}
