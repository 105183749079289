/**
 * 登录接口
 */
import axios from './http'

export const login = {
  // 登录接口
  login(params: any) {
    return axios.post('/api/c-pc/user/login', params)
  },
  // 退出登录
  logout() {
    return axios.post('/api/c-pc/user/logout')
  }
}
