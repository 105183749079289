


















































































import { Component, Vue, PropSync, Watch } from 'vue-property-decorator'
import { Menu, Submenu, MenuItem, MenuItemGroup, Badge } from 'element-ui'
Vue.use(Menu)
Vue.use(Submenu)
Vue.use(MenuItem)
Vue.use(MenuItemGroup)
Vue.use(Badge)

@Component
export default class Aside extends Vue {
  @PropSync('noticeCountTemp', { type: Object, default: {} })
  temp: any

  menuRouter = true
  defaultMenu = this.$route.path
  router = [] as any

  @Watch('$route')
  getRouter(val: any) {
    this.defaultMenu = val.path
  }

  mounted() {
    let routesCopy = [] as any
    // 拷贝一份菜单
    // 权限过滤
    routesCopy = JSON.parse(JSON.stringify(this.$router.options.routes))
    const user = sessionStorage.getItem('user')
    const infs = user && JSON.parse(user).infs
    let routesWithPrivs = [] as any
    routesWithPrivs = routesCopy.filter((route: any) => {
      return !(route.path && infs.indexOf(route.path) === -1)
    })

    routesWithPrivs.forEach((route: any) => {
      if (route.children) {
        route.children = route.children.filter(
          (v: any) => infs.indexOf(v.path) !== -1
        )
      }
    })
    routesWithPrivs.map((item: any) => {
      if (item.children.length === 0) {
        delete item.children
      }
    })
    routesWithPrivs.unshift({
      path: '/home',
      name: '首页',
      meta: { isShow: true, title: '首页', icon: 'shouye', newsNum: 0 },
      redirect: '/homepage'
    })
    this.router = routesWithPrivs
  }
}
