export const videoinfo = {
  status: [
    {
      value: '1',
      label: '全部状态'
    },
    {
      value: '2',
      label: '等待授权'
    },
    {
      value: '3',
      label: '已授权'
    },
    {
      value: '4',
      label: '授权过期'
    },
    {
      value: '5',
      label: '拒绝授权'
    }
  ],
  eventType: [
    {
      value: '1',
      label: '全部事件类型'
    },
    {
      value: '2',
      label: '事件类型一'
    },
    {
      value: '3',
      label: '事件类型二'
    }
  ],
  eventState: [
    {
      value: '1',
      label: '全部事件状态'
    },
    {
      value: '2',
      label: '未处理'
    },
    {
      value: '3',
      label: '已处理'
    }
  ],
  searchType: [
    {
      value: 'time',
      label: '事件时间'
    }
  ]
}
