<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import { Vue, Component } from 'vue-property-decorator'
import config from '../package.json'
@Component({})
export default class App extends Vue {
  mounted() {
    const style =
      'color: #89a1dc;font-size: 36px; padding: 20px; font-weight: bold;'
    console.log('%c当前版本:' + config.version, style)
  }
}
</script>
<style lang="scss">
#app {
  font-family: PingFangSC-Medium, PingFang SC, Avenir, Helvetica, Arial,
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
