import Vue from 'vue'
import { DirectiveOptions } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import api from './api' // 导入api接口
import './assets/images/svg'
import './utils/rem'

import constants from './constants' // 常量
import '@/assets/styles/common.scss'
import 'element-ui/lib/theme-chalk/index.css'
import 'element-tiptap/lib/index.css'
import * as directives from '@/directives/directives'

import {
  Row,
  Col,
  Form,
  FormItem,
  Input,
  Select,
  Option,
  Button,
  Loading,
  Message,
  MessageBox
} from 'element-ui'
import Viser from 'viser-vue'
import VueClipboard from 'vue-clipboard2'
/*  @ts-ignore */
import { ElementTiptapPlugin } from 'element-tiptap'
Vue.use(ElementTiptapPlugin, {
  lang: 'zh',
  spellcheck: false
})
Vue.use(Viser)
Vue.use(VueClipboard)
Vue.use(Row)
Vue.use(Col)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(Select)
Vue.use(Option)
Vue.use(Button)
Vue.use(Loading.directive)

Vue.config.productionTip = false
Vue.prototype.$api = api // 将api挂载到vue的原型上
Vue.prototype.$loading = Loading.service
Vue.prototype.$message = Message
Vue.prototype.$messagebox = MessageBox
Vue.prototype.$constants = constants

// 注册全局指令
Object.keys(directives).forEach(key => {
  Vue.directive(key, (directives as { [key: string]: DirectiveOptions })[key])
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
