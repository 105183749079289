/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * 首页接口列表
 */
import axios from './http'

export const home = {
  /**
   * 访客进出记录统计
   * @param deviceGroupIds
   * @param endDate
   * @param startDate
   */
  getFaceRecordCount(params: any) {
    return axios.get('/api/c-pc/index/face/records/count', { params })
  },
  /**
   * 车辆进出记录统计
   * @param deviceGroupIds
   * @param endDate
   * @param startDate
   */
  getCarCount(params: any) {
    return axios.get('/api/c-pc/index/count/carTravel', { params })
  },
  // 车闸流量记录
  getCarRecordList(params: any) {
    return axios.get('/api/c-pc/index/list/catLog', {
      params
    })
  },
  // 车闸流量记录
  getFaceRecordList(params: any) {
    return axios.get('/api/c-pc/index/list/faceLog', {
      params
    })
  },
  // 放行车辆
  checkCar(params: any) {
    return axios.post('/api/c-pc/index/checked/VisitCar', params)
  },
  // 首页消息通知
  getNoticeCount(userId: any) {
    return axios.get('/api/c-pc/webNotice/unchecked/' + userId)
  },
  // 消息通知列表
  getNoticeInfoList(params: any) {
    return axios.get('/api/c-pc/webNotice/list/page', { params })
  },
  // 已读消息
  checkedNotice(params: any) {
    return axios.get(
      '/api/c-pc/webNotice/read/' +
        params.userId +
        '?noticeId=' +
        params.noticeId
    )
  }
}
