/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * 人员设置
 */
import axios from './http'

export const people = {
  // --- 用户管理模块 ---
  // 用户列表
  getUserLists(params: any) {
    return axios.get('/api/c-pc/user/list/page', { params })
  },
  // 新增用户
  addUser(params: any) {
    return axios.post('/api/c-pc/user/add', params)
  },
  // 获取用户详情
  getUserDetail(id: any) {
    return axios.get(`/api/c-pc/user/detail/${id}`)
  },
  // 编辑用户
  editUser(params: any) {
    return axios.post('/api/c-pc/user/update', params)
  },
  // 删除用户
  deleteUser(id: any) {
    return axios.delete(`/api/c-pc/user/delete/${id}`)
  },
  // 修改密码
  editPwd(params: any) {
    return axios.post('/api/c-pc/user/update/pwd', params)
  },
  // --- 角色模块 ---
  // 角色列表
  getRoleLists(params: any) {
    return axios.get('/api/c-pc/role/list/page', { params })
  },
  // 新增角色
  addRole(params: any) {
    return axios.post('/api/c-pc/role/add', params)
  },
  // 获取角色详情
  getRoleDetail(id: any) {
    return axios.get(`/api/c-pc/role/detail/${id}`)
  },
  // 编辑角色
  editRole(params: any) {
    return axios.post('/api/c-pc/role/update', params)
  },
  // 删除角色
  deleteRole(id: any) {
    return axios.delete(`/api/c-pc/role/delete/${id}`)
  },
  // 获取菜单配置
  getMenuLists(params: any) {
    return axios.get('/api/c-pc/interface/list', { params })
  }
}
