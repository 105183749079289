/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * 设备管理接口列表
 */
import axios from './http'

export const equipment = {
  // --- 设备列表模块 ---
  // 设备列表
  getEquipmentList(params: any) {
    return axios.get('/api/c-pc/device/list/page', { params })
  },
  // 更新设备状态
  updateDeviceStatus(params: any) {
    return axios.post('/api/c-pc/device/updateStatus', params)
  },
  // 添加设备组
  addDeviceGroup(params: any) {
    return axios.post('/api/c-pc/deviceGroup/add', params)
  },
  // 获取所有设备组
  getDeviceGroups(params: any) {
    return axios.get('/api/c-pc/deviceGroup/list/page/deviceGroup', {
      params
    })
  },
  // 根据id获取设备详情
  getDeviceDetail(id: number) {
    return axios.get(`/api/c-pc/device/get/${id}`)
  },
  // 新增设备
  addEquip(params: any) {
    return axios.post('/api/c-pc/device/add', params)
  },
  // 编辑设备
  editEquip(params: any) {
    return axios.post('/api/c-pc/device/update', params)
  },

  // --- 设备运维模块 ---
  // 设备运维列表
  getEquipOptList(params: any) {
    return axios.get('/api/c-pc/deviceOperation/list/page', { params })
  },
  // 添加备注（设备运维）
  addOptRemark(params: any) {
    return axios.post('/api/c-pc/deviceOperation/add/remark', params)
  },
  // 设备运维 更换状态
  changeStatus(params: any) {
    return axios.post('/api/c-pc/deviceOperation/update/status', params)
  }
}
