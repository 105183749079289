/**
 * api接口的统一出口
 */

// 共用接口
import { common } from './common'
// 居民管理接口
import { resident } from './resident'
// 车辆管理接口
import { car } from './car'
// 设备管理接口
import { equipment } from './equipment'
// 访客管理接口
import { visitor } from './visitor'
// 物业管理接口
import { property } from './property'
// 人员设置接口
import { people } from './people'
// 登录接口
import { login } from './login'
// 首页
import { home } from './home'
// 门岗页面
import { gate } from './gate'
// 门禁卡管理页面
import { card } from './card'

// 导出接口
export default {
  common,
  resident,
  car,
  equipment,
  visitor,
  property,
  people,
  login,
  home,
  gate,
  card
}
