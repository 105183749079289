/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * 物业管理接口列表
 */
import axios from './http'

export const property = {
  // --- 报修管理模块 ---
  // 报修管理列表
  getRepairList(params: any) {
    return axios.get('/api/c-pc/repair/list/page', { params })
  },
  // 添加备注(报修管理)
  addRemark(params: any) {
    return axios.post('/api/c-pc/repair/addRemark', params)
  },
  // 维修人员派发
  distributeStaff(params: any) {
    return axios.post('/api/c-pc/repair/distribute', params)
  },
  //维修中
  repairHandling(params: any) {
    return axios.post('/api/c-pc/repair/handling', params)
  },
  // 完成
  addComplete(params: any) {
    return axios.post('/api/c-pc/repair/complete', params)
  },
  // 查询报修信息详情
  getRepairDetail(id: any) {
    return axios.get(`/api/c-pc/repair/get/${id}`)
  },
  // 更新维修人员信息
  editRepairDetail(params: any) {
    return axios.post('/api/c-pc/repair/update/repairer', params)
  },
  // --- 信息发布 ---
  publiceNotice(params: any) {
    return axios.post('/api/c-pc/notice/publish', params)
  },
  // 查询可推送房屋列表
  getPushHouseLists(params?: any) {
    return axios.get('/api/c-pc/house/list/push', { params })
  },
  // 查询发布历史信息列表
  getHistoryNoticeList(params: any) {
    return axios.get('/api/c-pc/notice/list/page', { params })
  },
  // 信息发布详情
  getNoticeInfo(id: any) {
    return axios.get(`/api/c-pc/notice/detail/${id}`)
  },
  // --- 业主意见模块 ---
  getOpinionList(params: any) {
    return axios.get('/api/c-pc/feedback/list/page', { params })
  },

  // --- 轮播图设置模块 ---
  // 轮播图列表
  getCarouselList(params: any) {
    return axios.get('/api/c-pc/carousel/list/page', { params })
  },
  // 新增轮播图
  addCarouse(params: any) {
    return axios.post('/api/c-pc/carousel/add', params)
  },
  // 编辑轮播图
  editCarouse(params: any) {
    return axios.post('/api/c-pc/carousel/update', params)
  },
  // 根据id查询当前轮播图详情
  getCarouseDetailById(id: any) {
    return axios.get(`/api/c-pc/carousel/detail/${id}`)
  },
  // 删除轮播图
  deleteCarouse(id: any) {
    return axios.delete(`/api/c-pc/carousel/delete/${id}`)
  },

  // --- 展示管理模块 ---
  // 展示列表
  getShowList(params: any) {
    return axios.get('/api/c-pc/display/list/page', { params })
  },
  // 更新展示状态
  updateShowState(params: any) {
    return axios.post('/api/c-pc/display/update/display', params)
  },
  // 删除展示
  deleteShow(id: number) {
    return axios.delete(`/api/c-pc/display/delete/${id}`)
  },
  // 添加展示
  addShowCont(params: any) {
    return axios.post('/api/c-pc/display/add', params)
  },
  //添加类型
  addShowType(params: any) {
    return axios.post('/api/c-pc/dictionary/add', params)
  },
  // 编辑展示
  editShowCont(params: any) {
    return axios.post('/api/c-pc/display/update', params)
  },
  // 根据id展示详情
  getShowDetail(id: number) {
    return axios.get(`/api/c-pc/display/detail/${id}`)
  },
  //上传图片
  uploadImage(params: any) {
    return axios.post('/api/common-all/upload', params)
  },

  // --- 物业人员管理模块 ---
  // 分页查询物业管理人员
  getStaffList(params: any) {
    return axios.get('/api/c-pc/staff/list/page', { params })
  },
  // 新增/更新物业管理人员
  addEditStaff(params: any) {
    return axios.post('/api/c-pc/staff/edit', params)
  },
  // 查看物业人员详情
  getStaffInfo(id: any) {
    return axios.get(`/api/c-pc/staff/detail/${id}`)
  },
  // 删除物业人员
  deleteStaff(id: any) {
    return axios.delete(`/api/c-pc/staff/delete/${id}`)
  },
  // 新增部门
  addNewDepartment(params: any) {
    return axios.post('/api/c-pc/department/add', params)
  },
  // 查询所有部门
  getDepartmentList(params: any) {
    return axios.get('/api/c-pc/department/list/page', { params })
  }
}
