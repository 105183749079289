import { DirectiveOptions } from 'vue'
import { DirectiveBinding } from 'vue/types/options'
import { VNode } from 'vue/types/vnode'

const showOverflowHiddenText = (
  el: HTMLElement,
  value: DirectiveBinding,
  vnode: VNode
) => {
  // console.log('vnode', vnode)
  // console.log('value', value)
  // console.log('el.parentNode', el.parentNode)
  // console.log('el.parentNode', (el.parentNode as any).attributes)
  // console.log('el', el)
  // console.log('el', el.attributes)
  // console.log('el', (el.parentNode as any).offsetWidth, (el.parentNode as any).scrollWidth)
  // console.log(el.offsetWidth, el.scrollWidth, el.clientWidth,  el.clientHeight, el.scrollHeight)
  // console.log('value', value)
  // v-title
  if (el.offsetWidth >= (el.parentNode as any).offsetWidth - 3) {
    let title = ''
    const re1 = new RegExp('<.+?>', 'g')
    title = el.innerHTML.replace(re1, '')
    el.setAttribute('title', title)
  }
}

export const Title: DirectiveOptions = {
  // bind(el, binding, vnode) {
  //   showOverflowHiddenText(el, binding)
  // }
  //溢出隐藏 并增加鼠标提示
  // inserted(el, binding, vnode) {
  //   showOverflowHiddenText(el, binding, vnode)
  // },
  componentUpdated(el, binding, vnode) {
    showOverflowHiddenText(el, binding, vnode)
  }
}
