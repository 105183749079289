export const houseinfo = {
  area: [
    {
      value: '1',
      label: '一期'
    },
    {
      value: '2',
      label: '二期'
    },
    {
      value: '3',
      label: '三期'
    }
  ],
  searchType: [
    {
      value: 'buildingNo',
      label: '房屋号'
    },
    {
      value: 'ownerName',
      label: '业主姓名'
    },
    {
      value: 'mobile',
      label: '联系方式'
    },
    {
      value: 'remark',
      label: '备注'
    }
  ]
}
