export const cardInfo = {
  searchType: [
    {
      value: 'buildingNo',
      label: '房屋号'
    },
    {
      value: 'houseCardNo',
      label: '卡号'
    },
    {
      value: 'remark',
      label: '备注'
    }
  ]
}
