import { common } from './common'
import { menulist } from './menu'
import { houseinfo } from './house'
import { checkinfo } from './check'
import { personinfo } from './person'
import { visitorinfo } from './visitor'
import { carinfo } from './car'
import { equipmentinfo } from './equipment'
import { videoinfo } from './video'
import { propertyinfo } from './property'
import { staffinfo } from './staff'
import { cardInfo } from './card'
import router from '../router/index'
export default {
  common,
  menulist,
  router,
  houseinfo,
  checkinfo,
  personinfo,
  visitorinfo,
  carinfo,
  equipmentinfo,
  videoinfo,
  propertyinfo,
  staffinfo,
  cardInfo
}
