/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * 门岗接口列表
 */
import axios from './http'

export const gate = {
  // --- 设备列表模块 ---
  // 设备列表
  getEquipmentList(params: any) {
    return axios.get('/api/c-pc/device/list/page', { params })
  }
}
