/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * 居民管理接口列表
 */
import axios from './http'

export const resident = {
  // --- 居民列表模块 ---
  // 居民列表
  getPeopleList(params: any) {
    return axios.get('/api/c-pc/resident/list/page', { params })
  },
  //居民详情
  getPeopleDetailById(id: any) {
    return axios.get(`/api/c-pc/resident/detail/${id}`)
  },
  //编辑居民
  updateResident(params: any) {
    return axios.post(`/api/c-pc/resident/update`, params)
  },
  //待审核分类数量
  getAuditCount(params: any) {
    return axios.get(`/api/c-pc/audit/audit/count`, { params })
  },
  //分页查询审核列表
  getAuditList(params: any) {
    return axios.get('/api/c-pc/audit/list/page', { params })
  },
  //查询审核详情
  getAuditDetailById(auditId: any) {
    return axios.get(`/api/c-pc/audit/detail/${auditId}`)
  },
  //审核
  checkAudit(params: any) {
    return axios.post('/api/c-pc/audit/audit', params)
  },
  //根据Id禁用居民门禁
  enableByResidentId(params: any) {
    return axios.get(`/api/c-pc/resident/enable/${params.residentId}?enable=` + params.enable)
  },
  //批量禁用/启用门禁
  enableAllBatch(params: any) {
    return axios.post('/api/c-pc/resident/enable/batch', params)
  },

  // --- 房屋列表模块 ---
  // 房屋列表
  getHouseList(params: any) {
    return axios.get('/api/c-pc/house/list/page', { params })
  },
  // 获取房屋详情
  getHouseInfo(id: any) {
    return axios.get(`/api/c-pc/house/detail/${id}`)
  },
  // 新增房屋（批量）
  addHouse(params: any) {
    return axios.post('/api/c-pc/house/import', params)
  },
  // 新增房屋（手动）
  hansAddHouse(params: any) {
    return axios.post('/api/c-pc/house/add', params)
  },
  // 编辑房屋
  editHouse(params: any) {
    return axios.post('/api/c-pc/house/update', params)
  },
  // 批量增加门禁权限
  addDeviceAuths(params: any) {
    return axios.post('/api/c-pc/house/add/deviceAuths', params)
  }
}
