/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * 门禁卡管理接口列表
 */
import axios from './http'

export const card = {
  // 门禁卡列表
  getCardKeyList(params: any) {
    return axios.get('/api/c-pc/accesscard/list/page', { params })
  },
  // 转换
  convertCard(params: any) {
    return axios.get('/api/c-pc/accesscard/convert', { params })
  },
  // 新增编辑门禁卡
  addEditCardKey(params: any) {
    return axios.post('/api/c-pc/accesscard/edit', params)
  },
  // 删除门禁卡
  deleteCardKey(id: any) {
    return axios.delete(`/api/c-pc/accesscard/del/${id}`)
  },
  // 门禁卡详情
  getCardKeyInfo(id: any) {
    return axios.get(`/api/c-pc/accesscard/detail/${id}`)
  }
}
