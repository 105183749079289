






import { Component, Prop, Vue } from 'vue-property-decorator'
@Component
export default class SvgIcon extends Vue {
  @Prop() private iconClass!: string
  @Prop() private className!: string
  get iconName() {
    return `#icon-${this.iconClass}`
  }
  get svgClass() {
    if (this.className) {
      return 'svg-icon ' + this.className
    } else {
      return 'svg-icon'
    }
  }
}
