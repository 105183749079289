export const equipmentinfo = {
  type: [
    {
      value: '1',
      label: '全部类型'
    },
    {
      value: '2',
      label: '智能门禁'
    },
    {
      value: '3',
      label: '智能车闸'
    },
    {
      value: '4',
      label: '智能摄像头'
    }
  ],
  groupType: [
    {
      value: '1',
      label: '门禁'
    },
    {
      value: '2',
      label: '监控'
    }
  ],
  state: [
    {
      value: '1',
      label: '全部状态'
    },
    {
      value: '2',
      label: '启用'
    },
    {
      value: '3',
      label: '停止'
    }
  ],
  state2: [
    {
      value: '1',
      label: '全部状态'
    },
    {
      value: '2',
      label: '未维护'
    },
    {
      value: '3',
      label: '已维护'
    }
  ]
}
