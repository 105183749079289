import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import Login from '../views/Login.vue'
import Layout from '@/components/Layout/index.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: '登录',
    component: Login
  },
  {
    path: '/home',
    name: '首页',
    meta: {
      isShow: true,
      title: '首页',
      icon: 'shouye',
      needNum: false,
      isDetail: false
    },
    component: Layout,
    redirect: '/homepage',
    children: [
      {
        path: '/homepage',
        name: '首页',
        meta: {
          isShow: false,
          title: '首页',
          icon: 'shouye',
          needNum: false,
          authority: [],
          isDetail: false
        },
        component: () => import('../views/home/index.vue')
      }
    ]
  },
  {
    path: '/gate',
    name: '门岗',
    meta: {
      isShow: true,
      title: '门岗',
      icon: 'shouye',
      needNum: false,
      isDetail: false
    },
    component: () => import('../views/gate-gard/index.vue')
  },
  {
    path: '/residents',
    name: '居民管理',
    meta: {
      isShow: true,
      title: '居民管理',
      icon: 'jumin',
      needNum: true,
      countType: 'JUMIN',
      newsNum: 0
    },
    component: Layout,
    redirect: '/residents/people-list',
    children: [
      {
        path: '/residents/people-list',
        name: '居民列表',
        meta: {
          isShow: true,
          title: '居民列表',
          newsNum: 0,
          authority: ['batchExport', 'batchDisableDoor', 'edit', 'disable'],
          isDetail: false
        },
        component: () => import('../views/residents/peopleList/index.vue')
      },
      {
        path: '/residents/people-edit',
        name: '居民编辑',
        meta: {
          isShow: false,
          title: '居民编辑',
          needNum: false,
          isDetail: true
        },
        component: () => import('../views/residents/peopleList/detail.vue')
      },
      {
        path: '/residents/owner-check',
        name: '业主审核',
        meta: {
          isShow: true,
          title: '业主审核',
          needNum: true,
          countType: 'OWNER_AUDIT',
          newsNum: 0,
          authority: ['detail'],
          isDetail: false
        },
        component: () => import('../views/residents/ownerCheck/index.vue')
      },
      {
        path: '/residents/owner-check-detail',
        name: '业主审核详情',
        meta: {
          isShow: false,
          title: '业主审核详情',
          newsNum: 0,
          isDetail: true
        },
        component: () => import('../views/residents/ownerCheck/detail.vue')
      },
      {
        path: '/residents/tenant-check',
        name: '租客审核',
        meta: {
          isShow: true,
          title: '租客审核',
          needNum: true,
          countType: 'TENANT_AUDIT',
          newsNum: 0,
          authority: ['detail'],
          isDetail: false
        },
        component: () => import('../views/residents/tenantCheck/index.vue')
      },
      {
        path: '/residents/tenant-check-detail',
        name: '租客审核详情',
        meta: {
          isShow: false,
          title: '租客审核详情',
          newsNum: 0,
          authority: [],
          isDetail: true
        },
        component: () => import('../views/residents/tenantCheck/detail.vue')
      },
      {
        path: '/residents/home-list',
        name: '房屋列表',
        meta: {
          isShow: true,
          title: '房屋列表',
          authority: [],
          isDetail: false
        },
        component: () => import('../views/residents/homeList/index.vue')
      },
      {
        path: '/residents/home-edit',
        name: '房屋编辑',
        meta: {
          isShow: false,
          title: '房屋编辑',
          needNum: false,
          authority: [],
          isDetail: true
        },
        component: () => import('../views/residents/homeList/edit-home.vue')
      },
      {
        path: '/residents/detail',
        name: '房屋详情',
        meta: {
          isShow: false,
          title: '房屋详情',
          needNum: false,
          authority: [],
          isDetail: true
        },
        component: () => import('../views/residents/homeList/detail.vue')
      }
    ]
  },
  {
    path: '/visitors',
    name: '访客管理',
    meta: {
      isShow: true,
      title: '访客管理',
      icon: 'fangke',
      needNum: false,
      authority: []
    },
    component: Layout,
    redirect: '/visitors/visitor-list',
    children: [
      {
        path: '/visitors/visitor-list',
        name: '进入记录',
        meta: {
          isShow: true,
          title: '进入记录',
          needNum: false,
          authority: [],
          isDetail: false
        },
        component: () => import('../views/visitors/visitorList/index.vue')
      },
      {
        path: '/visitors/visitor-history',
        name: '进出历史详情',
        meta: {
          isShow: false,
          title: '进出历史详情',
          needNum: false,
          authority: [],
          isDetail: true
        },
        component: () => import('../views/visitors/visitorList/history.vue')
      },
      {
        path: '/visitors/car-list',
        name: '访客车辆记录',
        meta: {
          isShow: true,
          title: '访客车辆记录',
          needNum: false,
          authority: [],
          isDetail: false
        },
        component: () => import('../views/visitors/carList/index.vue')
      },
      {
        path: '/visitors/car-history',
        name: '车辆到访历史详情',
        meta: {
          isShow: false,
          title: '车辆到访历史详情',
          needNum: false,
          authority: [],
          isDetail: true
        },
        component: () => import('../views/visitors/carList/history.vue')
      }
    ]
  },
  {
    path: '/cars',
    name: '车辆管理',
    meta: {
      isShow: true,
      title: '车辆管理',
      icon: 'cheliang',
      needNum: false,
      authority: []
    },
    component: Layout,
    redirect: '/cars/vehicle-list',
    children: [
      {
        path: '/cars/vehicle-list',
        name: '车辆列表',
        meta: {
          isShow: true,
          title: '车辆列表',
          needNum: false,
          authority: [],
          isDetail: false
        },
        component: () => import('../views/cars/vehicle/index.vue')
      },
      {
        path: '/cars/add-vehicle',
        name: '车辆信息',
        meta: {
          isShow: false,
          title: '车辆信息',
          needNum: false,
          authority: [],
          isDetail: true
        },
        component: () => import('../views/cars/vehicle/addCar.vue')
      },
      {
        path: '/cars/carport-list',
        name: '车位列表',
        meta: {
          isShow: true,
          title: '车位列表',
          needNum: false,
          authority: [],
          isDetail: false
        },
        component: () => import('../views/cars/carport/index.vue')
      },
      {
        path: '/cars/carport-info',
        name: '车位信息',
        meta: {
          isShow: false,
          title: '车位信息',
          needNum: false,
          authority: [],
          isDetail: true
        },
        component: () => import('../views/cars/carport/info.vue')
      },
      {
        path: '/cars/record-list',
        name: '车辆进出记录',
        meta: {
          isShow: true,
          title: '车辆进出记录',
          needNum: false,
          authority: [],
          isDetail: false
        },
        component: () => import('../views/cars/record/index.vue')
      },
      {
        path: '/cars/record-history',
        name: '车辆进出历史详情',
        meta: {
          isShow: false,
          title: '车辆进出历史详情',
          needNum: false,
          authority: [],
          isDetail: true
        },
        component: () => import('../views/cars/record/history.vue')
      }
    ]
  },
  {
    path: '/card-key',
    name: '门禁卡管理',
    meta: {
      isShow: true,
      title: '门禁卡管理',
      icon: 'mj',
      needNum: false,
      authority: []
    },
    component: Layout,
    redirect: '/card-key/list',
    children: [
      {
        path: '/card-key/list',
        name: '门禁卡列表',
        meta: {
          isShow: true,
          title: '门禁卡列表',
          needNum: false,
          authority: [],
          isDetail: false
        },
        component: () => import('../views/card-key/list/index.vue')
      },
      {
        path: '/card-key/card-add',
        name: '新增门禁卡',
        meta: {
          isShow: false,
          title: '新增门禁卡',
          needNum: false,
          authority: [],
          isDetail: true
        },
        component: () => import('../views/card-key/list/addCard.vue')
      }
    ]
  },
  {
    path: '/equipments',
    name: '设备管理',
    meta: {
      isShow: true,
      title: '设备管理',
      icon: 'shebei',
      needNum: false,
      authority: []
    },
    component: Layout,
    redirect: '/equipments/equipment-list',
    children: [
      {
        path: '/equipments/equipment-list',
        name: '设备列表',
        meta: {
          isShow: true,
          title: '设备列表',
          needNum: false,
          authority: [],
          isDetail: false
        },
        component: () => import('../views/equipments/list/index.vue')
      },
      {
        path: '/equipments/equipment-edit',
        name: '设备编辑',
        meta: {
          isShow: false,
          title: '设备编辑',
          needNum: false,
          authority: [],
          isDetail: true
        },
        component: () => import('../views/equipments/list/edit-equipment.vue')
      },
      {
        path: '/equipments/statistic',
        name: '设备统计',
        meta: {
          isShow: true,
          title: '设备统计',
          needNum: false,
          authority: [],
          isDetail: false
        },
        component: () => import('../views/equipments/statistic/index.vue')
      },
      {
        path: '/equipments/operation',
        name: '设备运维',
        meta: {
          isShow: true,
          title: '设备运维',
          needNum: false,
          authority: [],
          isDetail: false
        },
        component: () => import('../views/equipments/operation/index.vue')
      }
    ]
  },
  //  监控视频模块隐藏掉
  // {
  //   path: '/monitor',
  //   name: '监控视频',
  //   meta: {
  //     isShow: true,
  //     title: '监控视频',
  //     icon: 'jiankong',
  //     needNum: false,
  //     authority: []
  //   },
  //   component: Layout,
  //   redirect: '/monitor/video',
  //   children: [
  //     {
  //       path: '/monitor/video',
  //       name: '视频监控',
  //       meta: {
  //         isShow: true,
  //         title: '视频监控',
  //         needNum: false,
  //         authority: [],
  //         isDetail: false
  //       },
  //       component: () => import('../views/monitor/video/index.vue')
  //     },
  //     {
  //       path: '/monitor/video-record',
  //       name: '回放记录',
  //       meta: {
  //         isShow: false,
  //         title: '回放记录',
  //         needNum: false,
  //         authority: [],
  //         isDetail: true
  //       },
  //       component: () => import('../views/monitor/video/record.vue')
  //     },
  //     {
  //       path: '/monitor/video-apply',
  //       name: '回放申请',
  //       meta: {
  //         isShow: false,
  //         title: '回放申请',
  //         needNum: false,
  //         authority: [],
  //         isDetail: true
  //       },
  //       component: () => import('../views/monitor/video/apply.vue')
  //     },
  //     {
  //       path: '/monitor/alarm',
  //       name: '报警联动',
  //       meta: {
  //         isShow: true,
  //         title: '报警联动',
  //         needNum: false,
  //         authority: [],
  //         isDetail: false
  //       },
  //       component: () => import('../views/monitor/alarm/index.vue')
  //     },
  //     {
  //       path: '/monitor/playback',
  //       name: '监控回放',
  //       meta: {
  //         isShow: true,
  //         title: '监控回放',
  //         needNum: false,
  //         authority: [],
  //         isDetail: false
  //       },
  //       component: () => import('../views/monitor/playback/index.vue')
  //     }
  //   ]
  // },
  {
    path: '/property',
    name: '物业管理',
    meta: {
      isShow: true,
      title: '物业管理',
      icon: 'wuye',
      newsNum: 0,
      needNum: true,
      countType: 'WUYE',
      authority: []
    },
    component: Layout,
    redirect: '/property/repair',
    children: [
      {
        path: '/property/staff',
        name: '人员管理',
        meta: {
          isShow: true,
          title: '人员管理',
          needNum: false,
          authority: [],
          isDetail: false
        },
        component: () => import('../views/property/staff/index.vue')
      },
      {
        path: '/property/staffInfo',
        name: '人员信息',
        meta: {
          isShow: false,
          title: '人员信息',
          needNum: false,
          authority: [],
          isDetail: true
        },
        component: () => import('../views/property/staff/addInfo.vue')
      },
      {
        path: '/property/staff-history',
        name: '历史详情',
        meta: {
          isShow: false,
          title: '历史详情',
          needNum: false,
          authority: [],
          isDetail: true
        },
        component: () => import('../views/property/staff/history.vue')
      },
      // {
      //   path: '/property/repair',
      //   name: '报修管理',
      //   meta: {
      //     isShow: true,
      //     title: '报修管理',
      //     newsNum: 0,
      //     needNum: true,
      //     countType: 'REPAIR_APPLY',
      //     authority: [],
      //     isDetail: false
      //   },
      //   component: () => import('../views/property/repair/index.vue')
      // },
      {
        path: '/property/repair-detail',
        name: '报修详情',
        meta: {
          isShow: false,
          title: '报修详情',
          needNum: false,
          authority: [],
          isDetail: true
        },
        component: () => import('../views/property/repair/detail.vue')
      },
      {
        path: '/property/news',
        name: '信息发布',
        meta: {
          isShow: true,
          title: '信息发布',
          needNum: false,
          authority: [],
          isDetail: false
        },
        component: () => import('../views/property/news/index.vue')
      },
      {
        path: '/property/news-history',
        name: '历史信息',
        meta: {
          isShow: true,
          title: '历史信息',
          needNum: false,
          authority: [],
          isDetail: true
        },
        component: () => import('../views/property/news/history.vue')
      },
      {
        path: '/property/news-history-detail',
        name: '信息详情',
        meta: {
          isShow: false,
          title: '信息详情',
          needNum: false,
          authority: [],
          isDetail: true
        },
        component: () => import('../views/property/news/detail.vue')
      },
      {
        path: '/property/opinion',
        name: '业主意见',
        meta: {
          isShow: true,
          title: '业主意见',
          newsNum: 0,
          needNum: true,
          countType: 'OWNER_OPINION',
          authority: [],
          isDetail: false
        },
        component: () => import('../views/property/opinion/index.vue')
      },
      {
        path: '/property/carousel',
        name: '轮播图设置',
        meta: {
          isShow: true,
          title: '轮播图设置',
          needNum: false,
          authority: [],
          isDetail: false
        },
        component: () => import('../views/property/carousel/index.vue')
      },
      {
        path: '/property/carousel-edit',
        name: '轮播图编辑',
        meta: {
          isShow: true,
          title: '轮播图编辑',
          needNum: false,
          authority: [],
          isDetail: true
        },
        component: () => import('../views/property/carousel/edit.vue')
      },
      {
        path: '/property/show',
        name: '展示管理',
        meta: {
          isShow: true,
          title: '展示管理',
          needNum: false,
          authority: [],
          isDetail: false
        },
        component: () => import('../views/property/show/index.vue')
      },
      {
        path: '/property/show-edit',
        name: '展示编辑',
        meta: {
          isShow: false,
          title: '展示编辑',
          needNum: false,
          authority: [],
          isDetail: true
        },
        component: () => import('../views/property/show/edit.vue')
      }
    ]
  },
  {
    path: '/people',
    name: '人员设置',
    meta: {
      isShow: true,
      title: '人员设置',
      icon: 'renyuan',
      needNum: false,
      authority: []
    },
    component: Layout,
    redirect: '/people/administrator-list',
    children: [
      {
        path: '/people/administrator-list',
        name: '用户列表',
        meta: {
          isShow: true,
          title: '用户列表',
          needNum: false,
          authority: [],
          isDetail: false
        },
        component: () => import('../views/people/administrator/index.vue')
      },
      {
        path: '/people/administrator-edit',
        name: '编辑用户',
        meta: {
          isShow: false,
          title: '编辑用户',
          needNum: false,
          authority: [],
          isDetail: true
        },
        component: () => import('../views/people/administrator/edit.vue')
      },
      {
        path: '/people/role-list',
        name: '角色列表',
        meta: {
          isShow: true,
          title: '角色列表',
          needNum: false,
          authority: [],
          isDetail: false
        },
        component: () => import('../views/people/role/index.vue')
      },
      {
        path: '/people/role-edit',
        name: '编辑角色',
        meta: {
          isShow: false,
          title: '编辑角色',
          needNum: false,
          authority: [],
          isDetail: true
        },
        component: () => import('../views/people/role/edit.vue')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  NProgress.start()
  const u = sessionStorage.getItem('user')
  const user = u && JSON.parse(u)
  if (
    user &&
    user.infs &&
    user.infs.includes('/gate') &&
    to.path !== '/gate' &&
    to.path !== '/'
  ) {
    next({ path: '/gate' })
  }
  if (
    user &&
    user.infs &&
    !user.infs.includes('/gate') &&
    to.path === '/gate'
  ) {
    next({ path: '/homepage' })
  } else {
    next()
  }

  NProgress.done()
})

router.afterEach(() => {
  NProgress.done()
})

export default router
