export const checkinfo = {
  state: [
    {
      value: '1',
      label: '全部审核状态'
    },
    {
      value: '2',
      label: '待审核'
    },
    {
      value: '3',
      label: '未通过'
    },
    {
      value: '4',
      label: '已通过'
    }
  ],
  searchType: [
    {
      value: 'name',
      label: '姓名'
    },
    {
      value: 'mobile',
      label: '手机号'
    },
    {
      value: 'cardNo',
      label: '证件号码'
    }
  ],
  searchType2: [
    {
      value: 'name',
      label: '租客姓名'
    },
    {
      value: 'mobile',
      label: '手机号'
    },
    {
      value: 'cardNo',
      label: '证件号码'
    }
  ]
}
