/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  VuexModule,
  Module,
  Action,
  Mutation,
  getModule
} from 'vuex-module-decorators'
import store from '@/store'
// import { common } from '@/api/common'
// import { SettingsModule } from './settings'

export interface PersonState {
  userName: string
  menus: any[]
}

@Module({ dynamic: true, store, name: 'person' })
class Person extends VuexModule implements PersonState {
  $message: any
  $router: any
  public userName = '你好，请登录'
  public menus: any[] = []
  public subMenus: any[] = []

  @Mutation
  private SET_USERNAME(name: string) {
    this.userName = name
  }

  @Mutation
  private SET_MENUS(menus: any[]) {
    this.menus = menus
  }

  @Mutation
  private SET_SUBMENUS(menus: any[]) {
    this.subMenus = menus
  }

  @Action
  public async GetUserInfo() {
    // todo
  }
  @Action
  public ChangeSubMenus(menus: any[]) {
    this.SET_SUBMENUS(menus)
  }
  @Action
  public ChangeUsername(name: string) {
    this.SET_USERNAME(name)
  }
}

export const PersonModule = getModule(Person)
