export const staffinfo = {
  searchType: [
    {
      value: 'time',
      label: '登记时间'
    }
  ],
  searchType2: [
    {
      value: 'name',
      label: '姓名'
    }
  ]
}
