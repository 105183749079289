export const personinfo = {
  personType: [
    {
      value: '1',
      label: '业主'
    },
    {
      value: '2',
      label: '家属'
    },
    {
      value: '3',
      label: '租客'
    }
  ],
  searchType: [
    {
      value: 'name',
      label: '姓名'
    },
    {
      value: 'mobile',
      label: '手机号'
    },
    {
      value: 'buildingNo',
      label: '房屋号'
    },
    {
      value: 'remark',
      label: '备注'
    }
  ],
  status: [
    {
      value: '',
      label: '全部状态'
    },
    {
      value: '1',
      label: '正常'
    },
    {
      value: '2',
      label: '已禁用'
    }
  ]
}
