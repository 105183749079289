/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios'
import router from '../router/index'
import { Message } from 'element-ui'
import { PersonModule } from '@/store/modules/person'

/**
 * 跳转登录页
 * 携带当前页面路由，以期在登录页面完成登录后返回当前页面
 */
const toLogin = () => {
  sessionStorage.clear()
  localStorage.clear()
  router.replace({
    path: '/'
    // query: {
    //   redirect: router.currentRoute.fullPath
    // }
  })
}

/**
 * 请求失败后的错误统一处理
 * @param {Number} status 请求失败的状态码
 */
const errorHandle = (status: number, other: any) => {
  // 状态码判断
  switch (status) {
    // 401: 未登录状态，跳转登录页
    case 401:
      Message.error('请先登录')
      toLogin()
      break
    // 403 token过期
    // 清除token并跳转登录页
    case 403:
      Message.error('当前用户角色无权限')
      sessionStorage.removeItem('token')
      setTimeout(() => {
        toLogin()
      }, 1000)
      break
    // 404请求不存在
    case 404:
      Message.error('请求的资源不存在')
      break
    case 500:
      console.warn('500', other)
      break
    default:
      console.warn(other)
  }
}

// 创建axios实例
const instance = axios.create({ timeout: 1000 * 12 })

// 设置post请求头
instance.defaults.headers.post['Content-Type'] =
  'application/x-www-form-urlencoded'
/**
 * 请求拦截器
 * 每次请求前，如果存在token则在请求头中携带token
 */
instance.interceptors.request.use(
  config => {
    // 登录流程控制中，根据本地是否存在token判断用户的登录情况
    // 但是即使token存在，也有可能token是过期的，所以在每次的请求头中携带token
    // 后台根据携带的token判断用户的登录情况，并返回给我们对应的状态码
    // 而后我们可以在响应拦截器中，根据状态码进行一些统一的操作。
    const token = sessionStorage.getItem('token')
    token && (config.headers.Authorization = `Bearer ${token}`)
    // 移除空值参数
    for (const key in config.params) {
      if (
        config.params[key] === null ||
        config.params[key] === '' ||
        config.params[key] === undefined
      ) {
        delete config.params[key]
      }
    }
    for (const key in config.data) {
      if (
        config.data[key] === null ||
        config.data[key] === '' ||
        config.data[key] === undefined
      ) {
        delete config.data[key]
      }
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// 响应拦截器
instance.interceptors.response.use(
  // 请求成功
  // res => (res.status === 200 ? Promise.resolve(res) : Promise.reject(res)),
  res => {
    if (res.headers['access-token']) {
      sessionStorage.setItem('token', res.headers['access-token'])
    }
    const response = res.data
    switch (response.code) {
      case '200':
        return Promise.resolve(res)
      case '500':
        Message.error('服务器开小差~')
        return Promise.reject(res)
      case '400':
        Message.error(res.data.msg || res.data.code)
        return Promise.reject(res)
      case '401':
        Message.error('请先登录')
        toLogin()
        return Promise.reject(res)
      case '403':
        Message.error(res.data.msg || res.data.code)
        return Promise.reject(res)
      case 'S1000':
        Message.error(res.data.msg || res.data.code)
        return Promise.reject(res)
      case 'S1001':
        Message.error(res.data.msg || res.data.code)
        return Promise.reject(res)
      case 'S1002':
        Message.error(res.data.msg || res.data.code)
        return Promise.reject(res)
      case 'S1003':
        Message.error(res.data.msg || res.data.code)
        return Promise.resolve(res)
      case 'S1004':
        Message.error(res.data.msg || res.data.code)
        return Promise.reject(res)
      case 'S1005':
        Message.error(res.data.msg || res.data.code)
        return Promise.reject(res)
      case '901':
        Message.error('token失效，请重新登录')
        sessionStorage.removeItem('token')
        PersonModule.ChangeUsername('你好，请登录')
        setTimeout(() => {
          toLogin()
        }, 1000)
        return Promise.resolve(res)
      case 'L1001':
        Message.error(res.data.msg || res.data.code)
        return Promise.reject(res)
      case 'L1002':
        Message.error(res.data.msg || res.data.code)
        return Promise.reject(res)
      case 'L1003':
        Message.error(res.data.msg || res.data.code)
        return Promise.reject(res)
      case 'L1004':
        Message.error(res.data.msg || res.data.code)
        return Promise.reject(res)
      default:
        Message.error(res.data.msg || res.data.code)
        return Promise.reject(res)
    }
  },
  // 请求失败
  (error: { response: any }) => {
    const { response } = error
    if (response) {
      // 请求已发出，但是不在2xx的范围
      errorHandle(response.status, response.data.message)
      return Promise.reject(response)
    } else {
      // 处理断网的情况
      // eg:请求超时或断网时，更新state的network状态
      // network状态在app.vue中控制着一个全局的断网提示组件的显示隐藏
      // 关于断网组件中的刷新重新获取数据，会在断网组件中说明
      // store.commit('changeNetwork', false);
    }
  }
)

export default instance
