














































/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import Dialog from '@/components/Dialog/index.vue'

@Component({
  components: {
    Dialog
  }
})
export default class extends Vue {
  @Prop({ default: false }) private isShow!: boolean
  @Prop({ default: false }) private loading!: boolean

  @Watch('isShow')
  getIsShow(val: any) {
    if (val) {
      this.info = {
        rawPassword: '',
        password: '',
        confirmPassword: ''
      }
    }
  }
  info = {
    rawPassword: '',
    password: '',
    confirmPassword: ''
  }
  infoRules = {
    rawPassword: [
      { required: true, message: '请输入密码', trigger: 'blur' },
      { min: 6, message: '至少6位', trigger: 'blur' },
      { max: 20, message: '长度在20个字符以内', trigger: 'blur' },
      {
        pattern: /^[0-9a-zA-Z]+$/,
        message: '请输入英文或数字',
        trigger: 'blur'
      }
    ],
    password: [
      { required: true, message: '请输入密码', trigger: 'blur' },
      { min: 6, message: '至少6位', trigger: 'blur' },
      { max: 20, message: '长度在20个字符以内', trigger: 'blur' },
      {
        pattern: /^[0-9a-zA-Z]+$/,
        message: '请输入英文或数字',
        trigger: 'blur'
      },
      { validator: this.validateNewPass, trigger: 'blur' }
    ],
    confirmPassword: [
      { required: true, message: '请输入密码', trigger: 'blur' },
      { min: 6, message: '至少6位', trigger: 'blur' },
      { max: 20, message: '长度在20个字符以内', trigger: 'blur' },
      {
        pattern: /^[0-9a-zA-Z]+$/,
        message: '请输入英文或数字',
        trigger: 'blur'
      },
      { validator: this.validateConfirmPass, trigger: 'blur' }
    ]
  }
  validateNewPass(rule: any, value: any, callback: any) {
    if (value === this.info.rawPassword) {
      callback(new Error('新密码与原密码相同'))
    } else {
      callback()
    }
  }
  validateConfirmPass(rule: any, value: any, callback: any) {
    if (value !== this.info.password) {
      callback(new Error('两次输入新密码不相同'))
    } else {
      callback()
    }
  }
  closeDialog() {
    this.$emit('close', '')
  }
  dialogConfirm() {
    ;(this.$refs['info'] as any).validate((valid: any) => {
      if (valid) {
        this.$emit('optSuccess', this.info)
      }
    })
  }
}
