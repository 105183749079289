/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * 访客管理接口列表
 */
import axios from './http'

export const visitor = {
  // --- 进出记录模块 ---
  // 进出记录列表
  getVisitorList(params: any) {
    return axios.get('/api/c-pc/visitor/list/page', { params })
  },
  // 添加备注(访客)
  addRemark(params: any) {
    return axios.post('/api/c-pc/visitor/remark/add', params)
  },

  // --- 访客车辆记录模块 ---
  getVisitorCarList(params: any) {
    return axios.post('/api/c-pc/visit/car/list/page', params)
  },
  // 添加备注(访客车辆)
  addCarRemark(params: any) {
    return axios.post('/api/c-pc/visit/car/remark', params)
  },
  // 车辆到访历史详情列表
  getCarHistoryList(params: any) {
    return axios.get('/api/c-pc/visit/car/list', { params })
  },
  // 根据车牌号查询访客车辆信息
  getCarInfo(params: any) {
    return axios.get('/api/c-pc/visit/car/deatil', { params })
  },
  // 已预约访客车辆列表
  getAppointVisitorCarList(params: any) {
    return axios.post('/api/c-pc/visit/reserve/list/page', params)
  },
  // 根据预约id获取预约信息
  getReserveInfo(id: number) {
    return axios.get(`/api/c-pc/visit/reserve/detail/${id}`)
  }
}
